
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";


* {
  /* Text rendering */
  text-rendering: optimizeLegibility;
  -o-text-rendering: optimizeLegibility;
  -ms-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  -webkit-text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

* :focus {
   outline: none !important;
}

html {
  height: 100%;
  font-family: 'Source Sans Pro', sans-serif;
}

:root {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
}

html, body {
  height: 100%;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  width: 100%;
  flex: 1 1 auto;
  overflow: hidden;
}

h1, .h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
  vertical-align: center;
  letter-spacing: -0.5px;
}

h2, .h2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  vertical-align: center;
  letter-spacing: -0.5px;
}

h3, .h3 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  vertical-align: center;
  letter-spacing: -0.5px;
  margin: 0;
}

h4, .h4 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  vertical-align: center;
  letter-spacing: -0.5px;
  margin: 0;
}

h5, .h5 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  vertical-align: center;
  letter-spacing: -0.5px;
  margin: 0;
}

h6, .h6 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  vertical-align: center;
  letter-spacing: -0.5px;
}

p, .p {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  vertical-align: center;
  letter-spacing: -0.5px;
  margin: 0;
}

@media print { 
  html, body { 
    height: initial !important; 
    overflow: initial !important; 
  }
}